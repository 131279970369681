var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('a',{staticClass:"margin-bottom",staticStyle:{"border":"1px black"},on:{"click":_vm.goBack}},[_c('span',{staticClass:"fa fa-long-arrow-left"}),_vm._v(" Back")]),_c('br'),_c('br'),_c('div',{staticClass:"card"},[_vm._m(0),(_vm.appListData)?_c('div',{staticClass:"card-body"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Name"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("App List's name.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
                  initialValue: _vm.appListData.Name,
                  rules: [
                    { required: true, message: 'Name is required.' },
                    { max: 64, message: 'Maximum 64 characters allowed.' }
                  ]
                }]),expression:"['name', {\n                  initialValue: appListData.Name,\n                  rules: [\n                    { required: true, message: 'Name is required.' },\n                    { max: 64, message: 'Maximum 64 characters allowed.' }\n                  ]\n                }]"}],attrs:{"type":"text","placeholder":"App List Name"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"List Text"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("List of App bundles separated by new line.")])]),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['listText', {
                    initialValue: _vm.appListData.ListText,
                  }]),expression:"['listText', {\n                    initialValue: appListData.ListText,\n                  }]"}],attrs:{"autoSize":{ minRows: 1, maxRows: 20 },"type":"text","placeholder":"App List Name"}})],2)],1),_c('div',{staticClass:"ant-row ant-form-item"},[_c('div',{staticClass:"ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"}),_c('div',{staticClass:"ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper"},[_c('button',{staticClass:"btn btn-success px-5",attrs:{"type":"submit"}},[_vm._v("Save Changes")]),_c('button',{class:'btn btn-' + (_vm.appListData.Status === 0 ? 'danger' : 'primary') + ' px-5 ml-2',attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.toggleAppListStatus($event)}}},[_vm._v(" "+_vm._s(_vm.appListData.Status == 0 ? 'Deactivate' : 'Activate')+" App List ")]),_c('button',{staticClass:"btn px-5 ml-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'applists' })}}},[_vm._v(" Cancel ")])])])],1)],1):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"cui__utils__heading mb-0"},[_c('strong',[_vm._v("Edit App List")])]),_c('div',{staticClass:"text-muted"},[_vm._v("Edit App List information")])])}]

export { render, staticRenderFns }